.skills__container {
  justify-content: center;
}

.skills__title {
  display: flex;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
  justify-content: center;
}

.skills__box {
  display: flex;
  column-gap: 2.5rem;
  justify-content: space-evenly;
}

.skills__group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 1rem;
}

.skills__group-middle-column-split {
  display: none;
}

.skills__data {
  align-items: center;
  display: flex;
  column-gap: 0.5rem;
}

.skills__icon {
  width: 3rem;
  height: 3rem;
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--small-font-size);
}

/*========= BREAKPOINTS =========*/
/* For large devices */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

/* For medium devices */
@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
  }

  .skills__content {
    padding: 1rem;
  }

  /* .skills__box {
    columns: 2 auto;
    display: block;
    column-gap: initial;
  } */
  .skills__group-middle-column {
    display: none;
  }
  .skills__group-middle-column-split {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 1rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--small-font-size);
  }
}