.home__container {
  row-gap: 7rem;
}

.home__data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-2);
  text-align: center;
}

.home__hand {
  width: 38px;
  height: 38px;
  margin-left: 0.4rem;
}

.home__subtitle {
  position: relative;
  margin: var(--mb-2) 0;
  text-align: center;
  max-width: 600px;
}

.home__subtitle span {
  white-space: pre;
}

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
  text-align: center;
}

.home__img {
  background: url(../../assets/profile.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
  margin-right: auto;
  margin-left: auto;
  order: 1;
  width: 300px;
  height: 300px;
  animation: profile__animate 8s ease-in-out infinite;
}

@keyframes profile__animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home__scroll {
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(3.75rem);
  }
}

.home__scroll-name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}

.home__scroll-arrow {
  font-size: 1.25rem;
  color: var(--title-color);
}

.hide-scroll-down {
  display: none;
}

/*========= BREAKPOINTS =========*/
/* For large devices */
@media screen and (max-width: 992px) {
  .home__description {
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    width: 250px;
    height: 250px;
    box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    padding-top: 0rem;
  }

  .home__img {
    order: initial;
    box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 576px) {
  .home__title {
    font-size: var(--h1-font-size);
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .home__img {
    width: 180px;
    height: 180px;
  }
}