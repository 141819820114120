.qualifications__container {
  max-width: 992px;
}

.qualifications__sections {
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
}

.qualifications__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
  min-height: 90px;
}

.qualifications__data-left {
  text-align: right;
}

.qualifications__data-right {
  text-align: left;
}

.qualifications__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: -5px;
}

.qualifications__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-0-5);
}

.qualifications__calendar {
  font-size: var(--small-font-size);
}

.qualifications__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--text-color);
  border-radius: 50%;
}

.qualifications__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--text-color);
  transform: translate(6px, -7px);
}

/*========= BREAKPOINTS =========*/
/* For large devices */
@media screen and (max-width: 992px) {
  .qualifications__container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .qualifications__contaner {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}

@media screen and (max-width: 576px) {
  .qualifications__sections {
    grid-template-columns: initial;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .qualifications__data {
    gap: 0.5rem;
  }
}