.about__container {
  grid-template-columns: repeat(2, 1fr);
}

.about__img {
  width: 350px;
  border-radius: 1.5rem;
  justify-self: right;
}

.about__data {
  max-width: 350px;
}

.about__info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: var(--mb-3);
  justify-self: left;
}

.about__box {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.about__icon {
  font-size: 2.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}

.about__title {
  font-weight: var(--font-medium);
}

.about__subtitle {
  font-size: var(--small-font-size);
}

/*========= BREAKPOINTS =========*/
@media screen and (max-width: 576px) {
  .about__container {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }

  .about__data {
    justify-self: center;
  }
  
  .about__img {
    justify-self: center;
  }
}
